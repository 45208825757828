<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :options.sync="options"
    :server-items-length="totalItems"
    :loading="loading"
    sort-by="orderDate"
    :sort-desc="true"
    must-sort
    class=""
    calculate-widths
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ $t('Deals') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            :to="{ name: 'DealsCreate' }"
            fab
            dark
            color="primary"
        >
            <v-icon
            dark
            >
            mdi-plus
            </v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        flat
        class="mt-3"
      >
        <v-autocomplete
          :items="relations"
          item-text="name"
          item-value="id"
          v-model="options.relationId"
          clearable
          :label="$t('Filter Relations')"
          class="mt-3 pr-1 top-filters shrink"
          dense
        >
          <template v-slot:item="{item}">
              {{$t(item.name)}}
          </template>
        </v-autocomplete>
        <v-select
          v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1"
          :items="users"
          item-text="fullName"
          item-value="id"
          v-model="options.userId"
          clearable
          :label="$t('Filter Account Manager')"
          class="mt-3 pr-1 top-filters shrink"
          dense
        ></v-select>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-text-field
              v-model="offerDate"
              clearable
              class="mt-3 pr-1 top-filters shrink"
              v-bind="attrs"
              v-on="on"
              placeholder="dd-mm-yyyy"
              dense
        ></v-text-field>
        </template>
        <v-date-picker
              v-model="offerDateFormatted"
              no-title
              @input="menu2 = false"
              :first-day-of-week="1"
        ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menu3"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-text-field
              v-model="orderDate"
              clearable
              class="mt-3 pr-1 top-filters shrink"
              v-bind="attrs"
              v-on="on"
              placeholder="dd-mm-yyyy"
              dense
        ></v-text-field>
        </template>
        <v-date-picker
              v-model="orderDateFormatted"
              no-title
              @input="menu3 = false"
              :first-day-of-week="1"
        ></v-date-picker>
        </v-menu>
      </v-toolbar>
    </template>
    <template v-slot:item.relationId="{ item }">

        {{ getRelation(item.relationId) }}

    </template>
    <template v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1" v-slot:item.accountManagerId="{ item }">

        {{ getUser(item.accountManagerId) }}

    </template>
     <template v-slot:item.offerDate="{ item }">

        {{ item.offerDate == null ? "" : formatDate( new Date(item.offerDate).toISOString().substr(0, 10) )}}

    </template>
    <template v-slot:item.orderDate="{ item }">

        {{ item.orderDate == null ? "" : formatDate( new Date(item.orderDate).toISOString().substr(0, 10) )}}

    </template>
    <template v-slot:item.orderLines="{item}" >
      <div
        v-html="getOrderLines(item.orderLines)"
        style="white-space:pre">
      </div>
    </template>
    <template v-slot:item.actions="{item}" >
        <v-btn :to="{name: 'DealsEdit', query: { id: item.id, page: options.page }}" plain text icon class="mr-2">
            <v-hover v-slot="{ hover }">
            <v-icon :color="hover? 'green': 'grey'"
            >
            mdi-pencil
            </v-icon>
          </v-hover>
        </v-btn>

        <v-btn :to="{name: 'DealsDelete', query: { id: item.id,name:getRelation(item.relationId), page: options.page }}" plain text icon class="">
            <v-hover v-slot="{ hover }">
              <v-icon  :color="hover? 'primary': 'grey'"
              >
              mdi-delete
              </v-icon>
            </v-hover>
        </v-btn>
    </template>
    <template v-slot:no-data>
      {{ $t('No items found') }}
    </template>
  </v-data-table>
</template>

<script>
    export default {
        data(){
            return{
                search: '',
                listSrch: {},
                totalItems: 0,
                menu2:false,
                menu3:false,
                offerDate:'',
                orderDate:'',
                offerDateFormatted:'',
                orderDateFormatted:'',
                options: {},
                loading: true,
                relations:[],
                users:[],
                solutionTypes:[],
                headers: [],
                items: []
            }
        },
        computed: {

        },
        watch: {
            options: {
                handler () {
                  this.fetchItems()
                },
                deep: true,
            },
            offerDateFormatted () {
                this.offerDate = this.formatDate(this.offerDateFormatted)
                this.options.offerDate = this.offerDateFormatted
                this.fetchItems()
            },
            orderDateFormatted () {
                this.orderDate = this.formatDate(this.orderDateFormatted)
                this.options.orderDate = this.orderDateFormatted
                this.fetchItems()
            },
            offerDate () {
                this.offerDateFormatted = this.reFormatDate(this.offerDate)
                this.options.offerDate = this.offerDate
            },
            orderDate () {
                this.orderDateFormatted = this.reFormatDate(this.orderDate)
                this.options.orderDate = this.offerDateFormatted
            }
        },
        mounted ()
        {
            this.fetchItems();
        },
        created: function()
        {
            if(this.$route.query.page > 0){
              this.options.page = parseInt(this.$route.query.page);
            }

            this.fetchUsers();
            this.fetchRelations();
            this.fetchSolutionTypes();
            this.headers.push({ text: this.$t('Relation'), align: 'start', value: 'relationId'})

            if(this.$store.state.user.user.role == 0  || this.$store.state.user.user.role == 1){
              this.headers.push({ text: this.$t('Account Manager'), value: 'accountManagerId'})
            }

            this.headers.push(
              { text: this.$t('Offer Date'), value: 'offerDate'  },
              { text: this.$t('Order Date'), value: 'orderDate' },
              { text: this.$t('Amount'), value: 'amount' },
              { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false },
            )
        },

        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            reFormatDate (date) {
                if (!date) return null

                const [day, month, year] = date.split('-')
                return `${year}-${month}-${day}`
            },
            getOrderLines(OrderLines){
              return OrderLines;
            },
            getUser(userid){
              let filterUser = this.users.filter(function(user){
                return user.id == userid
              })
              return filterUser[0]!=undefined?filterUser[0].fullName:'';
            },
            getRelation(relationId){
              let filterRelation = this.relations.filter(function(Relation){
                return Relation.id == relationId
              })
              return filterRelation[0]!=undefined?this.$t(filterRelation[0].name):'';
            },
            fetchUsers()
            {
                let uri = this.$urlPrefix + '/User/CountAll?includeDeleted=true';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+'&includeDeleted=true';
                    this.axios.get(uri2).then((response) => {
                        this.users = response.data.filter(function(user){
                          return user.role == 1||user.role == 2;
                      });
                    });
                });
            },
            fetchRelations()
            {
                let uri = this.$urlPrefix + '/Relation/CountAll';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/Relation/GetList?start=0&count='+response.data;
                    this.axios.get(uri2).then((response) => {
                        this.relations = response.data;
                        this.loading = false;
                    });
                });
            },
            fetchSolutionTypes()
            {
                let uri = this.$urlPrefix + '/SolutionType/CountAll';
                this.axios.get(uri).then((response) => {this.$router.replace({ query: { page: this.options.page } }).catch(()=>{});
                    let uri2 = this.$urlPrefix + '/SolutionType/GetList?start=0&count='+response.data;
                    this.axios.get(uri2).then((response) => {
                        this.solutionTypes = response.data;
                        this.loading = false;
                    });
                });
            },
            fetchItems()
            {
              this.$router.replace({ query: { page: this.options.page } }).catch(()=>{});

              let uri = this.$urlPrefix + '/Deal/CountAll';
              let uri2 = this.$urlPrefix + '/Deal/GetList?start='+ (this.options.page -1) * this.options.itemsPerPage+'&count='+this.options.itemsPerPage;

              if(this.options.relationId){
                uri2 += '&relationId='+this.options.relationId;
              }

              if(this.options.userId){
                uri2 += '&accountManagerId='+this.options.userId;
              }

              if(this.options.offerDate){
                uri2 += '&offerDate='+this.options.offerDate;
              }

              if(this.options.orderDate){
                uri2 += '&orderDate='+this.options.orderDate;
              }

              if(this.options.sortBy){
                uri2 += '&sort='+this.options.sortBy[0];
              }
               if(this.options.sortDesc){
                uri2 += '&sortDescending='+this.options.sortDesc[0];
              }

              this.axios.get(uri).then((response) => {
                this.totalItems = response.data;
                this.axios.get(uri2).then((response) => {
                    this.items = response.data;
                    this.loading = false;
                });
              });
            },
        }
    }
</script>
<style lang="scss">
  .top-filters{
    width: 150px;
  }
</style>
