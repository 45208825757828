var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":"orderDate","sort-desc":true,"must-sort":"","calculate-widths":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Deals')))]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'DealsCreate' },"fab":"","dark":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-toolbar',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-autocomplete',{staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"items":_vm.relations,"item-text":"name","item-value":"id","clearable":"","label":_vm.$t('Filter Relations'),"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}]),model:{value:(_vm.options.relationId),callback:function ($$v) {_vm.$set(_vm.options, "relationId", $$v)},expression:"options.relationId"}}),(_vm.$store.state.user.user.role == 0  || _vm.$store.state.user.user.role == 1)?_c('v-select',{staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"items":_vm.users,"item-text":"fullName","item-value":"id","clearable":"","label":_vm.$t('Filter Account Manager'),"dense":""},model:{value:(_vm.options.userId),callback:function ($$v) {_vm.$set(_vm.options, "userId", $$v)},expression:"options.userId"}}):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"clearable":"","placeholder":"dd-mm-yyyy","dense":""},model:{value:(_vm.offerDate),callback:function ($$v) {_vm.offerDate=$$v},expression:"offerDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.offerDateFormatted),callback:function ($$v) {_vm.offerDateFormatted=$$v},expression:"offerDateFormatted"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"clearable":"","placeholder":"dd-mm-yyyy","dense":""},model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.orderDateFormatted),callback:function ($$v) {_vm.orderDateFormatted=$$v},expression:"orderDateFormatted"}})],1)],1)]},proxy:true},{key:"item.relationId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRelation(item.relationId))+" ")]}},(_vm.$store.state.user.user.role == 0  || _vm.$store.state.user.user.role == 1)?{key:"item.accountManagerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUser(item.accountManagerId))+" ")]}}:null,{key:"item.offerDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.offerDate == null ? "" : _vm.formatDate( new Date(item.offerDate).toISOString().substr(0, 10) ))+" ")]}},{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderDate == null ? "" : _vm.formatDate( new Date(item.orderDate).toISOString().substr(0, 10) ))+" ")]}},{key:"item.orderLines",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"pre"},domProps:{"innerHTML":_vm._s(_vm.getOrderLines(item.orderLines))}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'DealsEdit', query: { id: item.id, page: _vm.options.page }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'DealsDelete', query: { id: item.id,name:_vm.getRelation(item.relationId), page: _vm.options.page }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }